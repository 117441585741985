// ****************************************************
// [배포] 4.마이 > 휴대폰결제 > 사용가능 금액 변경
// ****************************************************
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import CommonRightIconHeader2 from "../../components/Common/CommonRightIconHeader";
import SectionChange2 from "../../components/MobilePayment/SectionChange2";
import SectionCTABtn2 from "../../components/MobilePayment/SectionCTABtn2";
import {useNavigate} from "react-router-dom";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import paymentApi from "../../api/gatewayApi/payment";
import {callCheckPassword, preparePaymentApi} from "../../utils/helper";
import CommonErrorPopup from "../../components/Common/CommonErrorPopup";
import {useMessagePopup} from "../../provider/MessagePopupProvider";
import {initLimit, LimitInfo} from "./types";
import BS_PaymentTerm from "../../components/Barcode/BS_PaymentTerm";
import {sendEventReturn} from "../../api/nativeBridge";
import {gtmCommonComp, gtmPageLoad} from "../../api/gtm";

export interface PaymentLimitHandles {
    handleCheckPassword: (password: string, isSuccess: boolean) => void;
    handleCheckPasswordTerm: (password: string) => void;
}

const MobilePaymentLimit = forwardRef<PaymentLimitHandles>((props, ref) => {
    const navigate = useNavigate()
    const [memberStatus, setMemberStatus] = useState("")
    const [buttonDisable, setButtonDisable] = useState(true)
    const [limitInfo, setLimitInfo] = useState<LimitInfo>(initLimit);
    const [amount, setAmount] = useState<number>(0); // 초기 금액
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [userStatus, setUserStatus] = useState('');
    const [isTermAgree, setIsTermAgree] = useState<boolean>(false);
    const {showMessagePopup} = useMessagePopup();
    const [showPaymentTermBs, setShowPaymentTermBs] = useState<boolean>(false);

    useEffect(() => {
        const getStatus = async () => {
            const status = await getItemByKey('memberStatus');
            const useTermYn = await getItemByKey('useTermYn');
            setIsTermAgree(useTermYn === 'Y');
            setMemberStatus(status)
            setUserStatus(await getItemByKey('userStatus'));

            //gtm
            await gtmPageLoad({
                url: '/mobile-pay/limit',
                contentGroup: '마이|휴대폰 결제|휴대폰 결제 설정',
                moduleName: '',
                pageTitle: '월 결제 한도 변경'
            });
        }
        getStatus()
        fetchLimit()
    }, []);

    useImperativeHandle(ref, () => ({
        handleCheckPassword: (password, isSuccess) => {
            if (isSuccess) {
                fetchLimitChange(password)
            } else {
                setButtonDisable(false)
            }
        },
        handleCheckPasswordTerm: async (password) => {
            const res = await preparePaymentApi('12', password, {}, paymentApi.postServiceUseTerm);
            if (res.header.resultCode === '0000') {
                setIsTermAgree(true);
                addItem('useTermYn', 'Y');
                fetchLimitChange(password)
            }
        }
    }))

    const fetchLimit = async () => {
        try {
            const res = await paymentApi.getLimit({}, 'v1');
            if (res.header.resultCode === "0000" && res.body) {
                setLimitInfo(res.body)
            }
        } catch (error) {
            console.log("Error fetchLimit:", error);
        }
    }

    const handleChangeLimit = async () => {
        if (userStatus === '44') {
            showMessagePopup('만 19세 미만 가입자는 PASS 앱 또는 당신의 U+앱에서 한도를 높일 수 있어요.')
            return;
        }

        if (!isTermAgree) {
            setShowPaymentTermBs(true);
            return;
        }

        // 25.01.15 금액 크고작음에 상관없이 패스워드 체크하도록 핫픽스스
        // if (amount <= Number(limitInfo.monthUseAmnt)) { // 변경 금액이 현재 금액보다 작을 경우 패스워드 체크를 하지 않아도 된다
        //     sendEventReturn('PASSWORD', {})
        // } else {
        callCheckPassword(`paymentLimit`);
        // }
        setButtonDisable(true)
    }

    const fetchLimitChange = async (password: string) => {
        console.log("amount:", amount.toString())
        const req = {
            settingLimAmt: amount.toString(),
        }
        const res = await preparePaymentApi('06', password, req, paymentApi.putLimit);
        if (res.header.resultCode === '0000') {
            showMessagePopup("월 결제 한도 변경이 완료되었습니다.")
            setLimitInfo({...limitInfo, monthUseAmnt: amount.toString()})
            gtmCommonComp('월 결제 한도 변경')
        } else {
            showMessagePopup(res.header.message);
        }
    }

    const handleTermAgree = async () => {
        callCheckPassword(`paymentLimit/term`);
    }

    const back = () => {
        navigate(-1)
    }
    return (
        <>
            <CommonRightIconHeader2 icon={"close_line"} title={"월 결제 한도 변경"} onClick={back}/>

            <SectionChange2 setButtonDisable={setButtonDisable} limitInfo={limitInfo} amount={amount}
                            setAmount={(amount) => setAmount((amount))}/>

            <SectionCTABtn2 status={memberStatus} buttonDisable={buttonDisable} onChange={handleChangeLimit}/>
            <CommonErrorPopup isOpen={showErrorPopup} onClose={() => setShowErrorPopup(false)}/>

            <BS_PaymentTerm isOpen={showPaymentTermBs} onClose={() => {
                setShowPaymentTermBs(false);
            }} onAgree={handleTermAgree}/>
        </>
    )
});

export default MobilePaymentLimit
