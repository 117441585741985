import React from "react";
import {Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {gtmDataLayer} from "../../api/gtm";
import {QuickAppList, QuickApp} from './types';
import useLandingPage from "../../hooks/useLandingPage";

interface QuickMenuProps {
    userStatus: string;
    quickMenu: QuickAppList;
}

const QuickMenu: React.FC<QuickMenuProps> = ({userStatus, quickMenu}) => {
    const navigate = useNavigate();
    const {landingPage} = useLandingPage();
    const handleQuickClick = (quick: QuickApp) => {
        if(quick.targetType === '117002') {
            if(quick.quickMenuName.includes('event')) {
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|이벤트',})
            } else if (quick.quickMenuName.includes('/attendance')) {
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|출석체크',})
            } else if (quick.quickMenuName.includes('vip-kok')) {
                gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|VIP콕',})
            }
        }
        if(quick.targetType === '117005') {
            gtmDataLayer({clickLocation: '바로가기 블록', clickText: '선택|영화예메',})
        }

        landingPage(quick.targetType, quick.targetLink, navigate, quick.quickMenuName);
    };

    return (
        <> {quickMenu.quickAppList.length > 0 && 
            <div
                style={{
                    padding: "12px 16px",
                    borderRadius: "12px",
                    background: "#fff",
                    border: "1px solid #E7EBEE",
                    display: "flex",
                    justifyContent: "space-evenly",
                }}
            >
                {quickMenu.quickAppList.map((quick, index: number) => {
                    // 250212 퀵메뉴 4개로 제한 > 소스변경
                    if(index < 4) 
                    return (
                        <React.Fragment key={quick.quickMenuName}>
                            {index > 0 && (
                            // index < quickMenu.quickAppList.length - 1 && (
                                <span style={{padding: "12 0px", width: "1px", height: "12px", color: "#E7EBEE"}}>|</span>
                            )}
                            <Text typo="font_label_2Sb" color="color_text_natural_6" style={{color: "#181A1B"}}
                                onClick={() => handleQuickClick(quick)}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'바로가기 블록 - 링크 클릭'}
                                data-gtm-event-label={`컨텐츠 : ${quick.quickMenuName}`}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'바로가기 블록'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={`${quick.quickMenuName}`}>
                                {quick.quickMenuName}
                            </Text>
                        </React.Fragment>
                    );
                })}
                </div>
        }</>
    );
};

export default QuickMenu;
