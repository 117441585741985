// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 받는사람 휴대폰 번호
import React, { Component, useEffect, useRef, useState } from "react";
import { Box, TextSet, TextField, Button, Text } from "@lguuxe/2024_designsystem_uni";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import { handlePromiseResult } from "../../api/api";
import { PopupProps } from "./UMembershipMyDatagift";

// interface AuthT {
//     code: string,
//     message: string,
// }

interface SectionHeadingTextfieldT {
    ctn: string;
    targetCtn: string;
    setTargetCtn: React.Dispatch<React.SetStateAction<string>>;
    setCtnAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    setPopup: React.Dispatch<React.SetStateAction<PopupProps | undefined>>;
    setSelTab: React.Dispatch<React.SetStateAction<string>>;
}

const SectionHeadingTextfield = ({ ctn, targetCtn, setTargetCtn, setCtnAvailable, setPopup, setSelTab }: SectionHeadingTextfieldT) => {
    const [authDisabled, setAuthDisabled] = useState(true);
    const [supportText, setSupportText] = useState<any>([]);

    const handleTextField = (e: any) => {
        setTargetCtn(e.value);
        if (e.value !== targetCtn) {
            setSupportText([]);
            setCtnAvailable(false);
        }
        if (e.value.length === 11) {
            setAuthDisabled(false);
        } else {
            setAuthDisabled(true);
        }
    };

    const ctnAuth = async () => {
        try {
            const auth = await membershipsServiceApi.dataGifts.auth.postIndex({ trgPhoneNo: targetCtn, ctn }, "v1");
            const message = auth.header?.message;
            const rcd = auth.header.resultCode;
            
            switch (rcd) {
                //성공
                case "0000":
                    // 본인번호일경우
                    if (targetCtn.slice(0, 3) + "0" + targetCtn.slice(3) === ctn) {
                        setPopup({
                            description: "고객님의 휴대폰 번호 입니다. 나에게 스스로 데이터 선물 하시겠습니까?",
                            submitText: "확인",
                        });
                    } else {
                        setPopup({
                            description: "데이터는 문자메시지를 통해\n'114'번호로 전달돼요.\n해당 번호를 스팸 번호로 등록하면 데이터를 못 받을 수 있으니 받는 분께 알려주세요.",
                            submitText: "확인",
                            title: "데이터 선물 안내",
                        });
                    }
                    
                    setCtnAvailable(true);
                    break;
                case "1160": // 데이터 선물 불가 대상
                case "1161": // U+ 모바일 해지or일시정지 대상
                case "1162": // 가족 아닐 경우
                    setSupportText([
                        {
                            icon: "",
                            status: "error",
                            text: message,
                        },
                    ]);
                    setCtnAvailable(false);
                    break;
                case "1163": // 이번 달 VIP 콕 이미 사용
                    setPopup({
                        description: message,
                        submitText: "내역보기",
                        cancelText: "닫기",
                        onSubmit: () => {
                            setSelTab("1");
                        },
                    });
                    setCtnAvailable(false);
                    break;
                case "1164": // 사용자의 포인트 부족
                case "1165": // 일시적인 오류 발생
                default:
                    setPopup({ description: message, submitText: "확인" });
                    setCtnAvailable(false);
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <Box type="2_trbl" style={{ paddingBottom: "24px" }}>
            <Text typo="font_heading_4B" color="color_text_neutral_6">
                받을 사람 휴대폰 번호
            </Text>
            <Box style={{marginTop: "12px", padding: 0}}>
                <TextField
                    fill="single"
                    inputType="number"
                    isAutoTab
                    maxLength={11}
                    placeholder="010 1234 5678"
                    onChange={handleTextField}
                    status="default"
                    rightAddons={[
                        <Button size="small" color="neutral" disabled={authDisabled} onClick={ctnAuth}>
                            인증하기
                        </Button>,
                    ]}
                    supportText={supportText.length > 0 ? supportText : undefined}
                    style={{whiteSpace: "pre-line"}}
                ></TextField>
            </Box>
        </Box>
    );
};

export default SectionHeadingTextfield;
