import React, {useEffect, useState} from "react";

interface TabContentHtmlProps {
    url: string;
}
const TabContent: React.FC<TabContentHtmlProps> = ({ url }) => {
    const [data, setData] = useState('');
    
    useEffect(() => {
        fetch(url)
            .then(response => response.text())
            .then(html => setData(html))
            .catch(err => console.error('Failed to fetch data:', err));
    }, [url]);
    
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    );
};

export default TabContent;
