// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기
// ****************************************************
import React, { useEffect, useState } from "react";
import { Tab, Box, CTA, Button } from "@lguuxe/2024_designsystem_uni";

import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import SectionHeadingInfobox from "./SectionHeadingInfobox";
import SectionHeadingTextfield from "./SectionHeadingTextfield";
import SectionHeadingTextarea from "./SectionHeadingTextarea";
import CommonDivider from "../../components/Common/CommonDivider";

import "../../css/style.css";
import { handlePromiseResult } from "../../api/api";
import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import SortingListHistory from "./SortingListHistory";
import DataGiftAccordion from "./DataGiftAccordion";
import { getItemByKey } from "../../utils/indexedDB";
import CommonPopup from "../../components/Common/CommonPopup";
import { useSnackbar } from "../../provider/SnackbarProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useMessagePopup } from "../../provider/MessagePopupProvider";

export interface LimitT {
    cardLevel: string;
    cardLevelName: string;
    dataGiftsSize: string;
    dataGiftsLimit: string;
    noticeList: Array<{
        title: string;
        contents: string;
    }>;
}

export interface PopupProps {
    description: string;
    submitText: string;
    onSubmit?: () => void;
    title?: string;
    cancelText?: string;
    onClose?: () => void;
}

const UMembershipMyDatagift = () => {
    const {showSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [isAvailable, setIsAvailable] = useState(false); //VIP or VVIP 인지
    const [limit, setLimit] = useState<LimitT>();
    const [selTab, setSelTab] = useState(""); // 현재 탭 [선물하기 1] [내역보기 2]
    const [ctn, setCtn] = useState(""); // 사용자 전화번호
    const [targetCtn, setTargetCtn] = useState(""); // 받는사람 휴대폰 번호
    const [ctnAvailable, setCtnAvailable] = useState(false); // 받는사람 휴대폰 번호 인증 통과 여부
    const [msg, setMsg] = useState(""); // 받는 사람에게 보낼 메시지
    const [popup, setPopup] = useState<PopupProps | undefined>(undefined);
    const query = useLocation();

    const dataGift = async () => {
        try {
            if(!isAvailable) return;
            const giftRes = await membershipsServiceApi.dataGifts.postSend({ ctn: ctn, trgPhoneNo: targetCtn, giftsMsg: msg }, "v1");
            const message = giftRes.header?.message;
            const rcd = giftRes.header.resultCode;

            switch (rcd) {
                case "0000": // 성공 > 토스트 노출
                    showSnackbar("데이터 1GB를 선물했어요.")
                    break;
                case "1163": // 이번 달 VIP 콕 이미 사용
                    setPopup({
                        description: message,
                        submitText: "내역보기",
                        cancelText: "닫기",
                        onSubmit: () => {
                            setSelTab("1");
                        },
                    });
                    break;
                case "1164": // 사용자의 포인트 부족
                case "1165": // 일시적인 오류 발생
                case "1166": // 데이터 선물을 보내고 있는 중
                default:
                    setPopup({
                        description: message,
                        submitText: "확인",
                    });
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(()=>{
        const tabIndex = new URLSearchParams(query.search).get('tabIndex');
        if(tabIndex) {
            setSelTab(tabIndex)
        } else {
            setSelTab("0")
        }
    },[query])

    useEffect(() => {
        if (selTab === "0") {
            //데이터 선물 가능 횟수 조회 API 호출
            const fetchData = async () => {
                const cardLevel = await getItemByKey("cardLevel");
                if (!["6", "8"].includes(cardLevel)) {
                    setPopup({
                        description: "VIP/VVIP 전용 혜택이에요.\n데이터 선물 내역만 확인할 수 있어요.",
                        submitText: "확인",
                        onSubmit: () => {
                            setSelTab("1");
                        },
                    });
                    return;
                }
                const useVipkokYn = await getItemByKey("useVipkokYn")
                if(useVipkokYn == 'Y') {
                    setPopup({
                        description: "이미 이번 달 VIP콕을 사용했어요.\n다음 달에 이용해주세요.",
                        submitText: "내역보기",
                        onSubmit: () => {
                            setSelTab("1");
                        },
                    });
                    return;
                }

                setIsAvailable(true);

                const ctn = await getItemByKey("ctn");
                setCtn(ctn);
                try {
                    const [limit] = await Promise.allSettled([membershipsServiceApi.dataGifts.getLimit({ ctn: ctn }, "v1")]);
                    setLimit(handlePromiseResult(limit));
                } catch (error) {
                    console.error(error);
                }
            };

            fetchData();
        }
    }, [selTab]);

    return (
        <>
            <Box
                type="1_b"
                style={{
                    width: "100%",
                }}
            >
                {/* header */}
                <CommonLeftIconHeader title="데이터 선물하기" />

                {/* 탭 메뉴 */}
                <Tab
                    items={[
                        {
                            id: "0",
                            label: "선물하기",
                        },
                        {
                            id: "1",
                            label: "내역보기",
                        },
                    ]}
                    layout="fullWidth"
                    selectedItemId={selTab}
                    onItemSelected={(index) => {
                        setSelTab(index);
                    }}
                    style={{
                        padding: "0 20px",
                    }}
                />

                {/* 선물 가능 횟수 */}
                {selTab === "0" && (
                    <>
                        {limit && <SectionHeadingInfobox limit={limit} />}

                        {/* 받는사람 휴대폰 번호 */}
                        <SectionHeadingTextfield ctn={ctn} targetCtn={targetCtn} setTargetCtn={setTargetCtn} setCtnAvailable={setCtnAvailable} setPopup={setPopup} setSelTab={setSelTab} />

                        {/* 선물 메시지 */}
                        <SectionHeadingTextarea msg={msg} setMsg={setMsg} />
                        <div
                            style={{
                                position: "fixed",
                                bottom: "0",
                                width: "100%",
                                zIndex: 1,
                            }}
                        >
                            <CTA layout="column" style={{ paddingBottom: "32px" }}>
                                <Button
                                    color="primary"
                                    size="large"
                                    disabled={!(ctnAvailable && msg.length > 0)}
                                    onClick={() => {
                                        setPopup({
                                            description: "데이터는 문자메시지로 전달돼요.\n지금 선물하시겠어요?",
                                            submitText: "선물하기",
                                            onSubmit: dataGift,
                                            cancelText: "취소",
                                        });
                                    }}
                                >
                                    선물하기
                                </Button>
                            </CTA>
                        </div>
                    </>
                )}
                {selTab === "1" && <SortingListHistory ctn={ctn} />}
                <CommonDivider />

                {/* 유의사항 */}
                <DataGiftAccordion noticeList={limit?.noticeList} />
            </Box>
            {!!popup?.description && (
                <CommonPopup
                    description={popup.description}
                    submitText={popup.submitText}
                    title={popup.title}
                    onSubmit={() => {
                        popup.onSubmit && popup.onSubmit();
                        setPopup(undefined);
                    }}
                    //왼쪽 버튼
                    cancelText={popup?.cancelText}
                    onClose={() => {
                        popup.onClose && popup.onClose();
                        setPopup(undefined);
                    }}
                />
            )}
        </>
    );
};

export default UMembershipMyDatagift;
