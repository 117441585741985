import {api, ApiEndpoints} from '../api';
import {ApiParams, ApiResponse} from '../types';

const membershipsServiceApi = {
    getMonthlyEvent: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.MONTH_EVENT, params, version),
    getMonthlyEventByDate: (evtDate: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.MONTH_EVENT_BY_DATE(evtDate), {}, version),
    getBasicBenefit: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.BASIC_BENEFIT, params, version),
    getVipBenefit: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.VIP_BENEFIT, params, version),
    getLoyalMemberBenefit: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.LOYAL_MEMBER_BENEFIT, params, version),
    getUPTPEvent: (eventId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.EVENT_DETAIL(eventId), {}, version),
    getUPTPApply: (body: Record<string, any>, eventId: string, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.MEMBERSHIP.UPTP.APPLY(eventId), body, version),
    postUPTPIssueCoupon: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.MEMBERSHIP.UPTP.ISSUE_COUPON, body, version),
    getVipBenefitThisMonth: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.VIP_BENEFIT_THIS_MONTH, {}, version),
    getVipBenefitSummary: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.VIP_BENEFIT_SUMMARY, params, version),
    getAlarm: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.ALARM, {}, version),
    putAlarm: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.MEMBERSHIP.ALARM, body, version),
    getCardInfo: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.CARD_INFO, params, version),
    getCardBarcode: (version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.CARD_BARCODE, {}, version),
    getUPTPEventCheck: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.UPTP.CHECK_APPLICANT, params, version),

    getRankInfo: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.RANK_INFO, params, version),
    getRankUpTips: (version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.RANK_UP_TIPS, {}, version),
    vipKok: {
        getInfo: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.INFO, {}, version),
        getUseHistory: (params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.USE_HISTORY, params, version),
        getPartnerCategories: (params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.PARTNER_CATEGORIES, params, version),
        getPartners: (params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.PARTNERS, params, version),
        check: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.CHECK, {}, version),
        getCategories: (version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.CATEGORIES, {}, version),
        getCategoriesById: (categoryId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.CATEGORIES_BY_ID(categoryId), params, version),
        getPartnersById: (ptnId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.VIP_KOK.PARTNER_BY_ID(ptnId), params, version),
    },
    dataGifts: {
        getLimit: (params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.DATA_GIFTS.LIMIT, params, version),
        auth: {
            postIndex: (body: Record<string, any>, version: string): Promise<ApiResponse> =>
                api.post(ApiEndpoints.MEMBERSHIP.DATA_GIFTS.AUTH.INDEX, body, version),
            postSms: (body: Record<string, any>, version: string): Promise<ApiResponse> =>
                api.post(ApiEndpoints.MEMBERSHIP.DATA_GIFTS.AUTH.SMS, body, version),
        },
        postSend: (params: ApiParams, version: string): Promise<ApiResponse> =>
            api.post(ApiEndpoints.MEMBERSHIP.DATA_GIFTS.SEND, params, version),
        getHistory: (params: ApiParams, version: string): Promise<ApiResponse> =>
            api.get(ApiEndpoints.MEMBERSHIP.DATA_GIFTS.HISTORY, params, version),
    },
    getMovieTheater: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.MOVIE.THEATER, params, version),
    getMovieTheaterByCode: (theaterCode: string, params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.MOVIE.THEATER_BY_CODE(theaterCode), params, version),
    getMovieTheaterList: (
        theaterCode: string,
        locationCode: string,
        params: ApiParams,
        version: string
    ): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.MOVIE.THEATER_LIST(theaterCode, locationCode), params, version),
    getMovieList: (
        theaterCode: string,
        locationCode: string,
        params: ApiParams,
        version: string
    ): Promise<ApiResponse> =>
        api.get(ApiEndpoints.MEMBERSHIP.MOVIE.MOVIE_LIST(theaterCode, locationCode), params, version),

    getSimpleCardInfo: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.SIMPLE_CARD_INFO, {}, version),
    getUseHistory: (yearMonth: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.MEMBERSHIP.USE_HISTORY(yearMonth), {}, version),
};

export default membershipsServiceApi;
