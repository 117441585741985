import {getItemByKey, removeItemByKey} from "../utils/indexedDB";
import {getGradeName} from "../pages/My/types";

const trackingEvent = async (jsonObject: any) => {
    const stringifiedObject = JSON.stringify(jsonObject);
    const os = await getItemByKey('platform')
    if (os === "IOS") {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BrazeBridgeTrackingEvent) {
            window.webkit.messageHandlers.BrazeBridgeTrackingEvent.postMessage(stringifiedObject);
        }
    } else {
        if (window.BrazeBridge && window.BrazeBridge.trackingEvent) {
            window.BrazeBridge.trackingEvent(stringifiedObject);
        }
    }
};

export const trackingAttr = async (jsonObject: any) => {
    const stringifiedObject = JSON.stringify(jsonObject);
    const os = await getItemByKey('platform')
    if (os === "IOS") {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.BrazeBridgeTrackingAttr) {
            window.webkit.messageHandlers.BrazeBridgeTrackingAttr.postMessage(stringifiedObject);
        }
    } else {
        if (window.BrazeBridge && window.BrazeBridge.trackingAttr) {
            window.BrazeBridge.trackingAttr(stringifiedObject);
        }
    }
};

export const brazeAttributeApi = {
    firstCollection: async () => {
        const adAgreeYn = await getItemByKey('adAgreeYn')
        const marketingAgreeYn = await getItemByKey('marketingAgreeYn');

        if (marketingAgreeYn !== 'Y') {
            return;
        }

        const moneyMe = await getItemByKey('moneyMeUse');
        const telcoCode = await getItemByKey('telcoCode');
        trackingAttr({own_third_type: telcoCode ? telcoCode === 'L' ? 'Y' : 'N' : ''});
        trackingAttr({member_type: getGradeName(await getItemByKey('cardLevel')) || ''});
        // trackingAttr({mydata_join: moneyMe ? moneyMe === 'Y' ? 'Y' : 'N' : ''});
        const vipkok = await getItemByKey('vipkok')
        const initVipkok = {
            mykok_use_month: '',
            mykok_name: '',
            mykok_cd: ''
        }
        trackingAttr({
            user_mykok_type: !vipkok ? initVipkok : {
                mykok_use_month: vipkok.isUsed ? vipkok.isUsed : 'N',
                mykok_name: vipkok.ptnName ?? null,
                mykok_cd: vipkok.ptnId ?? null
            }
        });
        trackingAttr({user_id: await getItemByKey('encMemberId') || ''});
        trackingAttr({gender: await getItemByKey('gender') || ''});
        const birthday = await getItemByKey('birthday');
        trackingAttr({birthdate: birthday.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')});
        trackingAttr({marketing_agreement_status: marketingAgreeYn || ''});
        trackingAttr({ad_agreement_app: adAgreeYn || ''});
        const longTermName = await getItemByKey('longTermName');
        const memberLoyalty = longTermName === 'NG1' ? '2년 이상' : longTermName === 'NG2' ? '5년 이상' : longTermName === 'NG3' ? '10년 이상' : '';
        trackingAttr({member_loyalty: memberLoyalty});
        trackingAttr({member_uth: await getItemByKey('youthYn') || ''});
    }
}

const timeFormat = (date: Date): string => {
    const currentTime = date.toISOString();
    const offset = new Date().getTimezoneOffset() * -1; // Timezone offset in minutes (KST is UTC+9)
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;

    const formattedOffset = `${offsetHours >= 0 ? "+" : "-"}${String(Math.abs(offsetHours)).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

    return `${currentTime.split(".")[0]}${formattedOffset}`;
}

const getBrazeInfo = async () => {
    const loginType = await getItemByKey('loginType');
    const signupDate = await getItemByKey('signupDate');
    const memberId = await getItemByKey('encMemberId');
    const telcoCode = await getItemByKey('telcoCode');
    const cardLevel = await getItemByKey('cardLevel');
    const vipkok = await getItemByKey('vipkok');
    const attendanceCnt = await getItemByKey('attendanceCnt');
    const lastAttendance = await getItemByKey('lastAttendance');
    const moneyMeUse = await getItemByKey('moneyMeUse');
    const moneyMePoint = await getItemByKey('moneyMePoint');
    const rewardPoint = await getItemByKey('rewardPoint');
    const utmSource = await getItemByKey('utmSource');
    if (utmSource) await removeItemByKey('utmSource');
    const utmContent = await getItemByKey('utmContent');
    if (utmContent) await removeItemByKey('utmContent');
    const adAgreeYn = await getItemByKey('adAgreeYn');
    const marketingAgreeYn = await getItemByKey('marketingAgreeYn');
    
    const now = new Date();

    return {
        // braze 속성들 중 시점떄문에 없을 경우 ''로 보내기로 협의 2025.01
        // mydate_join, mydata_money, utm_source, utm_content 삭제 2025.02
        event_occur_platform: 'app',
        custom_event_time: timeFormat(now),
        is_attendance_event: '',
        login_status: memberId ? 'Y' : 'N',
        login_type: loginType ? loginType : '',// 'MYLGID' || '휴대폰인증'
        signup_date: signupDate ? signupDate : '',// 본인인증(로그인)날짜
        own_third_type: telcoCode ? telcoCode === 'L' ? '자사' : '타사' : '',
        mykok_use_month: vipkok ? vipkok.isUsed : '',
        member_type: cardLevel ? getGradeName(cardLevel) : '',
        ad_agreement_app: adAgreeYn ? adAgreeYn === 'Y' ? 'Y' : 'N' : '',
        marketing_agreement_status: marketingAgreeYn ? marketingAgreeYn === 'Y' ? 'Y' : 'N' : '',
        attendance_cnt: attendanceCnt != null ? attendanceCnt : -1,
        last_attendance: lastAttendance ? lastAttendance : '',
        // mydata_join: moneyMeUse === 'Y' ? 'Y' : 'N',
        // mydata_money: moneyMePoint ? Number(moneyMePoint) : -1,
        available_mileage: rewardPoint ? Number(rewardPoint) : -1,
        // utm_source: utmSource ? utmSource : '',
        // utm_content: utmContent ? utmContent : '',
    };
}

const validateBeforeBrazeEvent = async (fn: () => Promise<void>) => {
    const marketingAgreeYn = await getItemByKey('marketingAgreeYn')
    if (marketingAgreeYn !== 'Y') {
        return;
    }
    await fn(); // 조건을 통과하면 원래 함수 실행
};

export const brazeEventApi = {
    tutorial_close: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'tutorial_close',
                custom_event_name: 'tutorial_close',
            };
            trackingEvent(params);
        }),
    mission_tab_enter: async (mileage: string) =>
        validateBeforeBrazeEvent(async () => {
            // 미션 탭 진입 시
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'mission_tab_enter',
                custom_event_name: 'mission_tab_enter',
                available_mileage: mileage ? Number(mileage) : -1,
            };
            trackingEvent(params);
        }),
    // 이탈 태그 사용 X
    mission_tab_exit: async (mileage: string) =>
        validateBeforeBrazeEvent(async () => {
            // 미션탭 진입 > 타 페이지로 이동 시
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'mission_tab_enter',
                custom_event_name: 'mission_tab_enter',
                // availableMileage: mileage,
            };
            trackingEvent(params);
        }),
    logIn_complete: async () =>
        validateBeforeBrazeEvent(async () => {
            // 로그인 완료 시 (자동/수동)
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'logIn_complete',
                custom_event_name: 'logIn_complete',
            };
            trackingEvent(params);
        }),

    attendance_check_complete: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'attendance_check_complete',
                custom_event_name: 'attendance_check_complete',
                is_attendance_event: 'N',
            };
            trackingEvent(params);
        }),
    my_tab_enter: async (useMoneyMe: boolean, moneyMePoint: number) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'my_tab_enter',
                custom_event_name: 'my_tab_enter',
                // mydata_join: useMoneyMe ? 'Y' : 'N',
                // mydata_money: moneyMePoint,
            };
            trackingEvent(params);
        }),
    // 이탈 태그 사용 X
    my_tab_exit: async (useMoneyMe: boolean, moneyMePoint: number) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'my_tab_exit',
                custom_event_name: 'my_tab_exit',
                // mydata_join: useMoneyMe ? 'Y' : 'N',
                // mydata_money: moneyMePoint,
            };
            trackingEvent(params);
        }),
    benefitplus_page_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'benefitplus_page_enter',
                custom_event_name: 'benefitplus_page_enter',
            };
            trackingEvent(params);
        }),
    event_participation_complete: async (eventName: string, eventId: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'event_participation_complete',
                custom_event_name: 'event_participation_complete',
                mkt_event_name: eventName,
                mkt_event_id: eventId,
                is_attendance_event: 'N',
            };
            trackingEvent(params);
        }),
    homemain_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'homemain_enter',
                custom_event_name: 'homemain_enter',
                // mobilePlanExpDate: '', 
            };
            trackingEvent(params);
        }),
    event_list_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'event_list_enter',
                custom_event_name: 'event_list_enter',
            };
            trackingEvent(params);
        }),
    event_detail_page_enter: async (eventName: string, eventId: string, isAttend: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'event_detail_page_enter',
                custom_event_name: 'event_detail_page_enter',
                mkt_event_name: eventName,
                mkt_event_id: eventId,
                is_attendance_event: isAttend,
            };
            trackingEvent(params);
        }),
    affiliateInfo_detail_page_enter: async (partnerName: string, partnerId: string, patnerCategory: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'affiliateInfo_detail_page_enter',
                custom_event_name: 'affiliateInfo_detail_page_enter',
                affiliate_name: partnerName,
                affiliate_id: partnerId,
                affiliate_sector: patnerCategory,
            };
            trackingEvent(params);
        }),
    total_menu_click: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'total_menu_click',
                custom_event_name: 'total_menu_click',
            };
            trackingEvent(params);
        }),
    home_barcode_floating_close: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'home_barcode_floating_close',
                custom_event_name: 'home_barcode_floating_close',
            };
            trackingEvent(params);
        }),
    vipKok_info_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'vipKok_info_enter',
                custom_event_name: 'vipKok_info_enter',
            };
            trackingEvent(params);
        }),


    affiliate_cpn_download_complete: async (partnerName: string, partnerId: string, patnerCategory: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'affiliate_cpn_download_complete',
                custom_event_name: 'affiliate_cpn_download_complete',
                affiliate_id: partnerId,
                affiliate_name: partnerName,
                affiliate_sector: patnerCategory,
            };
            trackingEvent(params);
        }),
    my_coupon_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'my_coupon_enter',
                custom_event_name: 'my_coupon_enter',
            };
            trackingEvent(params);
        }),
    basic_affiliate_list_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'basic_affiliate_list_enter',
                custom_event_name: 'basic_affiliate_list_enter',
            };
            trackingEvent(params);
        }),
    badgeking_page_enter: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'badgeking_page_enter',
                custom_event_name: 'badgeking_page_enter',
            };
            trackingEvent(params);
        }),
    home_recomm_benefit_click: async (partnerName: string, partnerId: string, patnerCategory: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'home_recomm_benefit_click',
                custom_event_name: 'home_recomm_benefit_click',
                affiliate_id: partnerId,
                affiliate_name: partnerName,
                affiliate_sector: patnerCategory,
            };
            trackingEvent(params);
        }),
    home_preferred_event_click: async (mkt_event_id: string, mkt_event_nm: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'home_preferred_event_click',
                custom_event_name: 'home_preferred_event_click',
                mkt_event_id: mkt_event_id,
                mkt_event_name: mkt_event_nm,
                is_attendance_event: 'N',
            };
            trackingEvent(params);
        }),
    home_barcode_floating_click: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'home_barcode_floating_click',
                custom_event_name: 'home_barcode_floating_click',
            };
            trackingEvent(params);
        }),
        
    //실시간 바코드 도입 취소, 태깅 불필요
    barcode_create: async () =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'barcode_create',
                custom_event_name: 'barcode_create',
            };
            trackingEvent(params);
        }),
    home_recent_affiliate_click: async (partnerName: string, partnerId: string, patnerCategory: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'home_recent_affiliate_click',
                custom_event_name: 'home_recent_affiliate_click',
                affiliate_id: partnerId,
                affiliate_name: partnerName,
                affiliate_sector: patnerCategory,
            };
            trackingEvent(params);
        }),
    mkt_popup_open: async (mkt_event_id: string, mkt_event_nm: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'mkt_popup_open',
                custom_event_name: 'mkt_popup_open',
                mkt_event_id: mkt_event_id,
                mkt_event_name: mkt_event_nm,
            };
            trackingEvent(params);
        }),
    mkt_popup_close: async (mkt_event_id: string, mkt_event_nm: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'mkt_popup_close',
                custom_event_name: 'mkt_popup_close',
                mkt_event_id: mkt_event_id,
                mkt_event_name: mkt_event_nm,
            };
            trackingEvent(params);
        }),
    favorite_click: async (partnerName: string, partnerId: string, patnerCategory: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'favorite_click',
                custom_event_name: 'favorite_click',
                affiliate_id: partnerId,
                affiliate_name: partnerName,
                affiliate_sector: patnerCategory,
            };
            trackingEvent(params);
        }),
    my_preferred_affiliate_cancel: async (partnerName: string, partnerId: string, patnerCategory: string) =>
        validateBeforeBrazeEvent(async () => {
            const info = await getBrazeInfo();
            const params = {
                ...info,
                EventName: 'my_preferred_affiliate_cancel',
                custom_event_name: 'my_preferred_affiliate_cancel',
                affiliate_id: partnerId,
                affiliate_name: partnerName,
                affiliate_sector: patnerCategory,
            };
            trackingEvent(params);
        }),
};
