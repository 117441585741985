import {api, ApiEndpoints} from '../api';
import {ApiParams, ApiResponse} from '../types';

const benefitApi = {
    getPrsMsg: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.PRS_MSG, params, version),
    getUptp: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.UPTP, params, version),
    getQuickMenu: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.QUICK_MENU, params, version),
    getRecentUsedBenefit: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.RECENT_USED_BENEFIT, params, version),
    getBenefitTag: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.BENEFIT_TAG, params, version),
    getBenefitTagById: (tagId: string, params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.BENEFIT_TAG_ID(tagId), params, version),
    getMovieRanking: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.MOVIE_RANKING, params, version),
    getEventTag: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.EVENT_TAG, params, version),
    getEventTagById: (tagId: string, params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.EVENT_TAG_ID(tagId), params, version),
    getRandomCategories: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.RANDOM.CATEGORIES, params, version),
    getRandomCategoryById: (categoryId: string, params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.RANDOM.CATEGORIES_ID(categoryId), params, version),
    getGuide: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.BENEFIT.GUIDE, params, version),
};

export default benefitApi;
