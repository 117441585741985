// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 선물 가능 횟수
import React, { Component, useEffect, useState } from "react";
import { TextSet, Text, Box, Image } from "@lguuxe/2024_designsystem_uni";

import membershipVvipIcon from "../../images/icon/1_grade/membership_24=vvip.svg";
import membershipVipIcon from "../../images/icon/1_grade/membership_24=vip.svg";
import membershipExcellentIcon from "../../images/icon/1_grade/membership_24=excellent.svg";
import membershipGeneralIcon from "../../images/icon/1_grade/membership_24=general.svg";
import { LimitT } from "./UMembershipMyDatagift";

const SectionHeadingInfobox = ({ limit }: { limit: LimitT }) => {
    return (
        <>
            <Box type="2_trbl">
                <Text typo="font_heading_4B" color="color_text_neutral_6">
                    선물 가능 횟수
                </Text>
                <Box
                    type="1_b"
                    style={{
                        paddingBottom: "0",
                    }}
                >
                    <Box
                        type="3_trbl"
                        style={{
                            marginTop: "12px",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "12px",
                            background: "#F3F5F6",
                        }}
                    >
                        <Box
                            type="2_tb"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8",
                                paddingTop: "4px",
                                paddingBottom: "4px",
                            }}
                        >
                            <Box style={{ display: "flex", gap: "8", flex: "1", padding: "0" }}>
                                <Image
                                    src={
                                        limit?.cardLevel === "6"
                                            ? membershipVvipIcon
                                            : limit?.cardLevel === "8"
                                            ? membershipVipIcon
                                            : limit?.cardLevel === "7"
                                            ? membershipExcellentIcon
                                            : membershipGeneralIcon
                                    }
                                    alt="placeholder"
                                    width="23px"
                                    ratio="1:1"
                                />
                                <Text typo="font_heading_5Sb" color="color_text_primary" style={{ paddingLeft: "8px" }}>
                                    {limit?.cardLevelName}
                                </Text>
                            </Box>
                            <Text typo="font_label_2M" color="color_text_primary">
                                {limit?.dataGiftsSize}
                                <Text typo="font_label_2M" color="color_text_neutral_5">
                                    /월{limit?.dataGiftsLimit}회
                                </Text>
                            </Text>
                        </Box>
                    </Box>
                </Box>
                {/* Text컴포넌트: font_body_1SB typo 필요 */}
            </Box>
        </>
    );
};

export default SectionHeadingInfobox;
