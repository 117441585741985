import React, {CSSProperties, FC, useEffect, useRef, useState} from "react";

import membershipsServiceApi from "../../api/gatewayApi/memberships-service";
import CommonPopup from "../Common/CommonPopup";
import {Box, TextSet, Toggle} from "@lguuxe/2024_designsystem_uni";
import {sendEventReturn} from "../../api/nativeBridge";
import {RecvInfo} from "../../pages/Setting/types";
import {gtmCommonComp} from "../../api/gtm";

interface AlarmSectionProps {
    appPush: boolean
    setAppPush: (arg0: boolean) => void
    recvInfo: RecvInfo
    setRecvInfo: (arg0: RecvInfo) => void
    memberStatus: string
    isFocus: boolean
}

const AlarmSection: FC<AlarmSectionProps> = ({appPush, setAppPush, recvInfo, setRecvInfo, memberStatus, isFocus}) => {
    const [openPopup, setOpenPopup] = useState<string | null>(null);
    let toggleRef = useRef<HTMLDivElement>(null);

    // 출석체크 이벤트 알림받기 팝업을 통해 들어올경우 스크롤조정
    useEffect(() => {
        if(isFocus && toggleRef.current) {
            toggleRef.current.scrollIntoView({
                behavior: 'smooth', // 부드럽게 스크롤
                block: 'start',    // 화면 상단에 맞춤
            });
        }
    }, [isFocus, toggleRef])

    const toggleDevicePush = async () => {
        const newDevicePush = !appPush
        if (newDevicePush) {// off -> on : 바로 알림설정으로 이동 && 디바이스 알람설정과 관계없이 토글 On
            sendEventReturn("OPEN_NOTIFICATION", {})
            setAppPush(newDevicePush)
            await membershipsServiceApi.putAlarm({appPushYn: 'Y', reqType: 'AP'}, 'v1');
            // gtm
            (window as any).digitalData.userInfo.push_agreement = 'Y'
            await gtmCommonComp('앱 푸시 설정 변경', 'Y')
        } else { // on -> off : 팝업 띄우고 알림설정으로 이동
            setAppPush(newDevicePush)
            setOpenPopup("devicePush")
            renderPopup();
            // gtm
            (window as any).digitalData.userInfo.push_agreement = 'N'
            await gtmCommonComp('앱 푸시 설정 변경', 'N')
        }
    };

    //popup이 뜨는 케이스의 경우에는 (앱 푸시 설정, 할인 설정) 팝업 버튼 클릭시점에 서버로 결과를 보내고
    //토글로만 동작하면 되는 케이스는 토글 동작시 서버로 결과를 보냅니다.
    const handleToggle = async (e: any) => {
        //@ts-ignore
        const initialRecvInfo = {...recvInfo, [e.id]: recvInfo[e.id] === "Y" ? "N" : "Y"};
        setRecvInfo(initialRecvInfo)
        //멤버쉽 할인 승인 누른 경우
        if (e.id === "aprPushYn") {
            if (initialRecvInfo["aprPushYn"] === "Y") { // off -> on
                if (appPush) {
                    const newRecvInfo = {...initialRecvInfo, smsYn: "Y", pushYn: "Y"}
                    setRecvInfo(newRecvInfo)
                    await membershipsServiceApi.putAlarm({...newRecvInfo, reqType: "MS"}, 'v1');
                    // gtm
                    (window as any).digitalData.userInfo = {
                        ...(window as any).digitalData.userInfo,
                        member_benefit_push: 'Y',
                        member_benefit_sms: 'Y'
                    };
                    await gtmCommonComp('멤버십 할인 승인 설정 변경', 'Y')
                } else { // 멤버십 승인을 켰는데 앱 푸시가 꺼져있는 경우
                    setRecvInfo({...recvInfo, smsYn: "Y", pushYn: "Y"})
                    setOpenPopup("deviceSettingDis")
                    renderPopup()
                }
            } else {
                setOpenPopup("membershipDiscount")
                renderPopup()
            }
        }
        //SMS가 바뀐 경우
        else if (e.id === "smsYn") {
            if (initialRecvInfo["smsYn"] === "N") { // sms : on-> off,
                if (initialRecvInfo["pushYn"] === "N") { // pushYn : off인 경우. aprPush 를 같이 꺼야한다
                    setOpenPopup("membershipDiscountSMS")
                    renderPopup()
                } else { // 그냥 끄면 된다.
                    await membershipsServiceApi.putAlarm({...initialRecvInfo, reqType: "MS"}, 'v1');
                    // gtm
                    (window as any).digitalData.userInfo.member_benefit_sms = 'N';
                    await gtmCommonComp('멤버십 할인 승인 설정 변경', 'Y > Y')
                }
            } else { //토글로만 동작 : sms off -> on
                await membershipsServiceApi.putAlarm({...initialRecvInfo, reqType: "MS"}, 'v1');
                // gtm
                (window as any).digitalData.userInfo.member_benefit_sms = 'Y'
                await gtmCommonComp('멤버십 할인 승인 설정 변경', 'Y > Y')
            }
            //push가 바뀐 경우(디바이스 앱푸시 설정 아님)
        } else if (e.id === "pushYn") {
            if (initialRecvInfo["pushYn"] === "N") { // push : on -> off
                if (initialRecvInfo["smsYn"] === "N") { // sms : off인 경우. aprPush 를 같이 꺼야한다
                    setOpenPopup("membershipDiscountSMS")
                    renderPopup()
                } else { // 팝업으로 끈다
                    setOpenPopup('pushYn')
                    renderPopup()
                }
            } else { // push : off -> on
                await membershipsServiceApi.putAlarm({...initialRecvInfo, reqType: "MS"}, 'v1');
                // gtm
                (window as any).digitalData.userInfo.member_benefit_push = 'Y'
                await gtmCommonComp('멤버십 할인 승인 설정 변경', 'Y > Y')

                // if (appPush) {
                //     //토글로만 동작 : 디바이스 푸쉬 켜진 상태에서 push on
                //     await membershipsServiceApi.putAlarm({...initialRecvInfo, reqType: "MS"}, 'v1')
                //     setRecvInfo(initialRecvInfo)
                // } else {
                //     setOpenPopup("deviceSetting")
                //     renderPopup()
                //     setRecvInfo({...initialRecvInfo, pushYn: "Y"})
                // }
            }
        }
    }

    const renderPopup = () => {
        switch (openPopup) {
            case 'membershipDiscount':
                return <CommonPopup description={"멤버십 할인 승인 알림을 끄면\n앱 푸시 또는 문자로 할인 내역을\n받을 수 없습니다.\n알림을 끄시겠어요?"}
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={async () => {
                                        const newRecvInfo = {aprPushYn: "N", smsYn: "N", pushYn: "N"}
                                        setRecvInfo(newRecvInfo)
                                        membershipsServiceApi.putAlarm({...newRecvInfo, reqType: "MS"}, 'v1');
                                        // gtm
                                        (window as any).digitalData.userInfo = {
                                            ...(window as any).digitalData.userInfo,
                                            member_benefit_push: 'N',
                                            member_benefit_sms: 'N'
                                        };
                                        await gtmCommonComp('멤버십 할인 승인 설정 변경', 'N')
                                        setOpenPopup(null)
                                    }}
                                    onClose={() => {
                                        // @ts-ignore
                                        const newRecvInfo = {...recvInfo, ["aprPushYn"]: "Y"}
                                        setRecvInfo(newRecvInfo)
                                        setOpenPopup(null)
                                    }}/>;
            case 'membershipDiscountSMS':
                return <CommonPopup description={"멤버십 할인 승인 알림을 끄면\n앱 푸시 또는 문자로 할인 내역을\n받을 수 없습니다.\n알림을 끄시겠어요?"}
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={async () => {
                                        const newRecvInfo = {aprPushYn: "N", smsYn: "N", pushYn: "N"}
                                        setRecvInfo(newRecvInfo)
                                        membershipsServiceApi.putAlarm({...newRecvInfo, reqType: "MS"}, 'v1');
                                        // gtm
                                        (window as any).digitalData.userInfo = {
                                            ...(window as any).digitalData.userInfo,
                                            member_benefit_push: 'N',
                                            member_benefit_sms: 'N'
                                        };
                                        await gtmCommonComp('멤버십 할인 승인 설정 변경', 'N')
                                        setOpenPopup(null)
                                    }}
                                    onClose={() => {
                                        // @ts-ignore
                                        const newRecvInfo = {...recvInfo, pushYn: "Y"}
                                        setRecvInfo(newRecvInfo)
                                        setOpenPopup(null)
                                    }}/>;
            case 'pushYn':
                return <CommonPopup description="앱 푸시를 끄면 멤버십 할인 승인 알림 등 푸시 정보를 받을 수 없습니다. 앱 푸시를 끄시겠어요?"
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={async () => {
                                        const newRecvInfo = {...recvInfo, pushYn: "N"}
                                        membershipsServiceApi.putAlarm({...newRecvInfo, reqType: "MS"}, 'v1');
                                        // gtm
                                        (window as any).digitalData.userInfo.member_benefit_push = 'N'
                                        await gtmCommonComp('멤버십 할인 승인 설정 변경', 'Y > Y')
                                        setOpenPopup(null)
                                    }}
                                    onClose={() => {
                                        const newRecvInfo = {...recvInfo, pushYn: "Y"}
                                        setRecvInfo(newRecvInfo)
                                        setOpenPopup(null)
                                    }}/>;
            case 'devicePush':
                return <CommonPopup description="앱 푸시를 끄면 멤버십 할인 승인 알림 등 푸시 정보를 받을 수 없습니다. 앱 푸시를 끄시겠어요?"
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={() => {
                                        membershipsServiceApi.putAlarm({appPushYn: 'N', reqType: 'AP'}, 'v1')
                                        setOpenPopup(null)
                                        sendEventReturn("OPEN_NOTIFICATION", {})
                                    }}
                                    onClose={() => {
                                        setOpenPopup(null)
                                        setAppPush(true)
                                    }}/>;
            case 'deviceSetting':
                return <CommonPopup description={"핸드폰에서 알림을 먼저 켜주세요.\n알림 설정 화면으로 이동할까요?"}
                                    submitText="이동"
                                    cancelText="취소"
                                    onSubmit={() => {
                                        setOpenPopup(null)
                                        sendEventReturn("OPEN_NOTIFICATION", {})
                                    }}
                                    onClose={() => {
                                        setRecvInfo({...recvInfo, pushYn: "N"})
                                        setOpenPopup(null)
                                    }}/>;
            case 'deviceSettingDis':
                return <CommonPopup description={"핸드폰에서 알림을 먼저 켜주세요.\n알림 설정 화면으로 이동할까요?"}
                                    submitText="이동"
                                    cancelText="취소"
                                    onSubmit={() => {
                                        setOpenPopup(null)
                                        sendEventReturn("OPEN_NOTIFICATION", {target: 'membership'})
                                    }}
                                    onClose={() => {
                                        const newRecvInfo = {aprPushYn: "N", smsYn: "N", pushYn: "N"}
                                        setRecvInfo(newRecvInfo)
                                        setOpenPopup(null)
                                    }}/>;
            default:
                return null;
        }
    }


    return (
        <div>
            <div ref={toggleRef} style={{marginBottom: "12px"}}>
                <Box type="2_trbl">
                    <TextSet subComponent="Heading_4" text={{
                        title: '알림',
                        description:
                            <span>
                                기기에서 알림을 허용해야 알림을 받을 수 있어요.
                                <br/>
                                알림이 오지 않는 경우 기기 설정에서 알림을 켜주세요.
                            </span>
                    }} weight="default"/>
                </Box>

                <div>
                    {/* 앱 푸시 설정 */}
                    <div style={sectionStyle}>
                        <TextSet
                            subComponent="Body_1"
                            text={{title: '앱 푸시 설정'}}
                            weight="bolder"
                        />
                        <Toggle
                            id="appPushYn"
                            checked={appPush}
                            onChange={toggleDevicePush}
                            size="medium"
                            trueValue="value"
                            value="value"
                            weight="default"
                            width="auto"
                        />
                    </div>

                    {memberStatus !== "5" && (
                        <>
                            {/* 멤버십 할인 승인 */}
                            <div style={sectionStyle}>
                                <TextSet
                                    subComponent="Body_1"
                                    text={{title: '멤버십 할인 승인'}}
                                    weight="bolder"
                                />
                                <Toggle
                                    id="aprPushYn"
                                    checked={recvInfo.aprPushYn === "Y"}
                                    onChange={handleToggle}
                                    size="medium"
                                    trueValue="value"
                                    value="value"
                                    weight="default"
                                    width="auto"
                                />
                            </div>

                            {/* 앱 푸시 및 문자 */}
                            <div style={toggleContainerStyle}>
                                <div style={sectionStyle}>
                                    <TextSet
                                        subComponent="Body_1"
                                        text={{title: '앱 푸시'}}
                                        weight="bolder"
                                    />
                                    <Toggle
                                        id="pushYn"
                                        checked={recvInfo.pushYn === "Y"}
                                        onChange={handleToggle}
                                        size="medium"
                                        trueValue="value"
                                        value="value"
                                        weight="default"
                                        width="auto"
                                        disabled={recvInfo.aprPushYn === "N"}
                                    />
                                </div>

                                <div style={sectionStyle}>
                                    <TextSet
                                        subComponent="Body_1"
                                        text={{title: '문자'}}
                                        weight="bolder"
                                    />
                                    <Toggle
                                        id="smsYn"
                                        checked={recvInfo.smsYn === "Y"}
                                        onChange={handleToggle}
                                        size="medium"
                                        trueValue="value"
                                        value="value"
                                        weight="default"
                                        width="auto"
                                        disabled={recvInfo.aprPushYn === "N"}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {renderPopup()}
        </div>
    );
};

const sectionStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 20px",
};

const toggleContainerStyle: CSSProperties = {
    borderRadius: "12px",
    backgroundColor: "#F3F5F6",
    margin: "0 20px",
};

export default AlarmSection