// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 유의사항
import React, { Component } from "react";
import { Accordion, Text, Button, TextSet } from "@lguuxe/2024_designsystem_uni";

const DataGiftAccordion = ({ noticeList }: { noticeList?: Array<{ title: string; contents: string }> }) => {
    return (
        <div>
            <Accordion
                fill="underline"
                title={
                    <TextSet
                        subComponent="Heading_5"
                        text={{
                            title: "꼭 확인하세요",
                        }}
                    />
                }
                size="medium"
                weight="bolder"
                style={{
                    padding: "0",
                    marginBottom: "84px",
                    gap: 0
                }}
                expanded
            >
                <Accordion.Body>
                    <div>
                        <Text typo="font_body_2M">데이터 선물하기</Text>
                        <ul style={{padding: "0 20px", margin: 0}}>
                            <li><Text typo="font_body_2M">VIP콕은 월 1회 이용 가능해요.</Text></li>
                            <li><Text typo="font_body_2M">3G 기기를 이용하는 고객 또는 기본 제공 데이터가 없는 요금제를 이용하는 고객에게는 데이터를 선물할 수 없어요.</Text></li>
                            <li><Text typo="font_body_2M">만 19세 미만 고객 또는 청소년 요금제를 이용 중인 고객은 결합한 가족에게만 선물할 수 있어요.</Text></li>
                            <li><Text typo="font_body_2M">선물한 뒤에는 취소할 수 없어요.</Text></li>
                        </ul>
                        <Text typo="font_body_2M" style={{marginTop: "1rem"}}>데이터 선물받기</Text>
                        <ul style={{padding: "0 20px", margin: 0}}>
                            <li><Text typo="font_body_2M">선물받은 데이터는 돌려주거나 거절할 수 없어요.</Text></li>
                            <li><Text typo="font_body_2M">다른 통신사 이용 고객, 휴대폰 해지 및 일시 정지 중인 고객은 선물받을 수 없어요.</Text></li>
                            <li><Text typo="font_body_2M">2G, 3G, 패드 요금제 등 일부 요금제를 이용하는 고객의 경우, 데이터를 선물 받을 수 없어요. 자세한 내용은 고객센터로 문의해 주세요.</Text></li>
                            <li><Text typo="font_body_2M">데이터는 문자 메시지를 통해 '114'번호로 전달돼요. 해당 번호를 스팸 번호로 등록하면 데이터를 못 받을 수 있으니 받는 분께 알려주세요.</Text></li>
                        </ul>
                        <Text typo="font_body_2M" style={{marginTop: "1rem"}}>데이터 사용</Text>
                        <ul style={{padding: "0 20px", margin: 0}}>
                            <li><Text typo="font_body_2M">선물 받은 데이터로 쉐어링(데이터 나눠쓰기), 테더링(데이터 함께쓰기), 데이터 음성 통화(MVoIP)는 이용할 수 없어요.</Text></li>
                            <li><Text typo="font_body_2M">선물 받은 데이터는 요금제 기본 제공 데이터 보다 먼저 사용돼요.</Text></li>
                            <li><Text typo="font_body_2M">선물 받은 날을 포함해 31일 동안 사용 가능하고, 휴대폰 일시 정지 기간도 유효기간에 포함돼요.</Text></li>
                            <li><Text typo="font_body_2M">더 궁금한 내용은 휴대폰으로 LG유플러스 고객센터 114(무료)로 문의해 주세요.</Text></li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion>
        </div>
    );
};

export default DataGiftAccordion;
