import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import CommonPopup from "../components/Common/CommonPopup";

interface PopupContextType {
    showMessagePopup: (content: string) => void;
    showCustomPopup: (content: any) => void;
    hidePopup: () => void;
    isVisible: boolean;
    content: string;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const useMessagePopup = () => {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }
    return context;
};

export const MessagePopupProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [content, setContent] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [submitText, setSubmitText] = useState<string>('');
    const [onSubmit, setOnSubmit] = useState<(() => void)>(()=>{});
    const [cancelText, setCancelText] = useState<string>('');
    const [onClose, setOnClose] = useState<(() => void) | undefined>(()=>{});

    const showMessagePopup = (content: string) => {
        init();
        setContent(content);
        setIsVisible(true);
    };

    const showCustomPopup = (content: any) => {
        init();
        setContent(content.description);
        content.title && setTitle(content.title);
        content.submitText && setSubmitText(content.submitText);
        content.onSubmit && setOnSubmit(()=>()=>{content.onSubmit(); hidePopup()});
        content.cancelText && setCancelText(content.cancelText);
        content.onClose && setOnClose(()=>()=>{content.onClose(); hidePopup()});
        setIsVisible(true);
    }

    const hidePopup = () => {
        setIsVisible(false);
        setTimeout(() => {
            init();
        }, 300);
    };

    const init = () => {
        setContent('');
        setTitle('');
        setSubmitText('');
        setOnSubmit(()=>{});
        setCancelText('');
        setOnClose(()=>{});
    }

    return (
        <PopupContext.Provider value={{showMessagePopup, showCustomPopup, hidePopup, isVisible, content}}>
            {children}
            <CommonPopup
                submitText={submitText || '확인'}
                title={title}
                description={content}
                onSubmit={onSubmit || hidePopup}
                cancelText={cancelText}
                onClose={onClose || hidePopup}
                isOpen={isVisible}
            />
        </PopupContext.Provider>
    );
};
