import {sendEvent, sendEventReturn} from "./nativeBridge";
import {getItemByKey} from "../utils/indexedDB";
import {ENVIROMENT} from "./api";

const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api-services.members.lgudevplatform.com'
    : 'https://api-serviced.members.lgudevplatform.com'

/**
 * 챗봇
 */
const CHATBOT_DEV_URL = 'https://chatbot-dev.lguplus.com/?fromChan=';
const CHATBOT_STG_URL = 'https://chatbot-stg.lguplus.com/?fromChan=';
const CHATBOT_PROD_URL = 'https://chatbot.lguplus.com/?fromChan=';
export const chatbot = async () => {
    const memberId = await getItemByKey('memberId');
    const os = await getItemByKey('platform')
    const url = ENVIROMENT === 'prod' ? CHATBOT_PROD_URL : ENVIROMENT === 'stg' ? CHATBOT_STG_URL : CHATBOT_DEV_URL;
    const htmlUrl = 'https://cv.uplus.kr/320.html'
    // const param = {
    //     url: os === 'IOS' ? url + 'MEMIOS' : url + 'MEMAPP',
    //     interfaceId: '202',
    //     custNo: memberId,
    //     entrNo: await getItemByKey(''),  //가입번호
    //     loginYn: 'Y',
    //     deviceType: 'MOBILE',
    //     openChan: os === 'IOS' ? 'MEMIOS' : 'MEMAPP',
    //     serviceChan: 'APP',
    //     openMenu: '',
    //     openMenuId: '',
    //     openUrl: '',
    //     userId: await getItemByKey('ctn'),
    //     loginTypeCode: 'H',
    //     loginTypeName: 'ID로그인',
    // }
    // sendEvent('EXTERNAL_CALL', param)
    sendEventReturn('EXTERNAL_CALL', {type: '1', url: htmlUrl});
}

/* ============================================================================================================
 * 개인정보처리방침
 * ============================================================================================================*/
export const privacyPolicy = async () => {
    // 개인정보처리방침 외부 연결 URL 25.02.17
    const htmlUrl = 'https://privacy.lguplus.com/54e397b85ae1413eacbb6dc9dbdc88a9/privacy/info/v1/1'
    sendEventReturn('EXTERNAL_CALL', {type: '1', url: htmlUrl});
}

/* ============================================================================================================
 * 앱테크 연동
 * ============================================================================================================*/
const APPTECH_PROD_URL = 'https://webapp-front.prd.dhadbiz.co.kr'
const APPTECH_STG_URL = 'https://webapp-front.stg.dhadbiz.co.kr'
const APPTECH_DEV_URL = 'https://daehong-offerwall-webapp-front-test.mock.pe.kr'
// const COUPON_MALL = '?target=COUPON_MALL'
export const appTech = async (urlParams?: string) => {
    const carrier = await getItemByKey('telcoCode');
    const appVersion = await getItemByKey('appVersion');
    const uri = ENVIROMENT === 'prod' ? APPTECH_PROD_URL : ENVIROMENT === 'stg' ? APPTECH_STG_URL : APPTECH_DEV_URL;
    const url = `${uri}?appversion=${appVersion}${urlParams ? '&' + urlParams : ''}`;
    const param = {
        url: url,
        carrier: carrier,
    }
    console.log('OPEN_APPTECH param : ', param)
    sendEvent('OPEN_APPTECH', param);
}

/* ============================================================================================================
 *  머니미
 * ============================================================================================================*/
const MONEYME_DEV_URL = 'https://dev-mydata.lguplus.com:8081';
const MONEYME_STG_URL = 'https://stg-mydata.lguplus.com:8081'
const MONEYME_PROD_URL = 'https://mydata.lguplus.com:8081'
export const moneymeEnter = async (urlParam?: string) => {
    const url =
        ENVIROMENT === 'prod' ?
            MONEYME_PROD_URL :
            ENVIROMENT === 'stg' ?
                MONEYME_STG_URL : MONEYME_DEV_URL;
    const param = {
        url: url,
        extraLinkUrl: urlParam ?? '',
    };
    sendEventReturn('OPEN_MONEYME', param);
}

/*  ============================================================================================================
 *  광고 ssp
 *  ============================================================================================================*/
export const adSSP = async (type: string, timeout: number = 3000) => {
    const appVersion = await getItemByKey('appVersion');
    const platform = await getItemByKey("platform");
    const subNo = await getItemByKey("subNo") || '';
    const osVersion = await getItemByKey("osVersion") || '';
    const model = await getItemByKey('model');
    const adid = await getItemByKey('adid');

    // 기본 URL 설정
    let baseUrl;
    let placement;
    let queryParams: any = {
        'device-model': model,
        country: 'KOR',
        'app-id': 'uplus.membership',
        'device-orientation': 'VERTICAL',
        language: 'ko',
        'app-name': 'membership',
        'app-version': appVersion,
        'device-os-version': osVersion,
        application: 'APP',
        publisher: 'LGUPLUS',
        'user-agent': 'UNKNOWN',
    };
    const restAPI = '/api/v1/recommend';
    const nonIdentifiedURL = ENVIROMENT === 'prod' ? `https://api-adid.adssp.uplus.co.kr${restAPI}` : ENVIROMENT === 'stg' ? `https://api-adid.stg-adssp.uplus.co.kr${restAPI}` : `https://api-adid.dev-adssp.uplus.co.kr${restAPI}`;
    const identifiedURL = ENVIROMENT === 'prod' ? `https://api.adssp.uplus.co.kr${restAPI}` : ENVIROMENT === 'stg' ? `https://api.stg-adssp.uplus.co.kr${restAPI}` : `https://api.dev-adssp.uplus.co.kr${restAPI};`
    if (platform === 'AOS') {
        queryParams.platform = 'ANDROID';

        if (type === '비식별') {
            baseUrl = nonIdentifiedURL;
            placement = 'MEMBERS_POPUP_APP_AOS';
            queryParams['device-adid'] = adid;
        } else if (type === '식별') {
            baseUrl = identifiedURL;
            placement = 'MEMBERS_MAIN_LIST_APP_AOS';
            queryParams['user-id'] = subNo;
        } else {
            throw new Error("Invalid type specified");
        }
    } else if (platform === 'IOS') {
        baseUrl = identifiedURL;
        placement = 'MEMBERS_MAIN_LIST_APP_IOS';
        queryParams.platform = 'IOS';
        queryParams['user-id'] = subNo; // iOS는 항상 식별 가능한 형태로 요청
    } else {
        throw new Error("Unsupported platform");
    }

    queryParams.placement = placement;

    // 쿼리 스트링 생성
    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${baseUrl}?${queryString}`;
    console.log(baseUrl);
    console.log(queryString);

    // 타임아웃 설정된 fetch 함수
    const fetchWithTimeout = (url: string, options: any, timeout: number) => {
        return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeout)
            )
        ]);
    };

    try {
        const response = await fetchWithTimeout(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }, timeout);

        // TypeScript에서 response 타입 명시
        if (!response || !(response instanceof Response)) {
            throw new Error("Invalid response type");
        }

        if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("API request error:", error);
        return [];
    }
};

/** ============================================================================================================
 * 영화 예매
 * ============================================================================================================*/
//const MOVIE_PROD = 'https://membership.lguplus.co.kr:30001/movie/mobile/intro.lgum?isMembers=Y'
//const MOVIE_STG_DEV = 'http://ex-membership.lguplus.co.kr:30001/movie/mobile/intro.lgum?isMembers=Y'
////const MOVIE_STG_DEV = 'http://106.103.227.32:30001/movie/mobile/intro.lgum?isMembers=Y'
// const MOVIE_PROD = 'https://movie-service.members.lguplatform.com:25057/movie/mobile/intro.lgum?isMembers=Y'
// const MOVIE_STG = 'https://movie-services.members.lgudevplatform.com:25057/movie/mobile/intro.lgum?isMembers=Y'
// const MOVIE_DEV = 'https://movie-serviced.members.lgudevplatform.com:25057/movie/mobile/intro.lgum?isMembers=Y'
const MOVIE_PROD = 'https://movie-service.members.lguplatform.com/movie/mobile/intro.lgum?isMembers=Y'
const MOVIE_STG = 'https://movie-services.members.lgudevplatform.com/movie/mobile/intro.lgum?isMembers=Y'
const MOVIE_DEV = 'https://movie-serviced.members.lgudevplatform.com/movie/mobile/intro.lgum?isMembers=Y'

const MOVIE_PROD_HISTORY = 'https://movie-service.members.lguplatform.com/renew/html/movie/mobile/mypage.html?isMembers=Y'
const MOVIE_STG_HISTORY = 'https://movie-services.members.lgudevplatform.com/renew/html/movie/mobile/mypage.html?isMembers=Y'
const MOVIE_DEV_HISTORY = 'https://movie-serviced.members.lgudevplatform.com/renew/html/movie/mobile/mypage.html?isMembers=Y'

export const goMovie = async (goHistory?: boolean) => {
    const token = await getItemByKey("token");
    const encKey = await getItemByKey("encKey");
    const clv = await getItemByKey("cardLevel");
    const ci = await getItemByKey("ci");
    const userName = await getItemByKey("customerName");

    // Construct the URL based on whether agent is passed as a parameter
    // 'prod' | 'stg' | 'dev'
    let url = ENVIROMENT === 'prod' ? MOVIE_PROD : ENVIROMENT === 'stg' ? MOVIE_STG : MOVIE_DEV;
    if (goHistory) {
        url = ENVIROMENT === 'prod' ? MOVIE_PROD_HISTORY : ENVIROMENT === 'stg' ? MOVIE_STG_HISTORY : MOVIE_DEV_HISTORY;
    }

    const movieParam = {
        url: url,
        token: token,
        encKey: encKey,
        clv: clv,
        ci: ci,
        userName: userName
    };
    console.log("movieURL:", movieParam)
    await sendEvent("OPEN_MOVIE_RESERVATION", movieParam);
}

/** ============================================================================================================
 * 데이터 선물하기 또는 데이터 내역보기
 * ============================================================================================================*/
// index 0: 선물하기, 1: 데이터 내역보기
export const goMoveData = async (index: string) => {
    const token = await getItemByKey("token")
    const encKey = await getItemByKey("encKey")
    const clv = await getItemByKey("cardLevel")
    const ci = await getItemByKey("ci")
    const userName = await getItemByKey("customerName")

    const url = ENVIROMENT === 'prod'
        ? `https://membership.lguplus.co.kr:30001/renew/html/datakok/data_give.html?isMembers=Y&tabIndex=${index}&kokCode=CL`
        : `http://106.103.227.32:30001/renew/html/datakok/data_give.html?isMembers=Y&tabIndex=${index}&kokCode=CL`;
    const moveDataParam = {
        url: url,
        token: token,
        encKey: encKey,
        clv: clv,
        ci: ci,
        userName: userName,
    }
    console.log(moveDataParam.url)
    await sendEvent("MOVE_DATA", moveDataParam)
}
