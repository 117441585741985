export type UserInfo = {
    email: string;       // 이메일
    zipCode: string;     // 우편번호
    address1: string;    // 주소1
    address2: string;    // 주소2
};

export type Push = {
    termId: string;      // 혜택정보/광고 제공 약관 ID
    termsYn: string;     // 약관동의 여부
    appPushOnOff: string;  // 앱푸시 ON/OFF 설정
    smsOnOff: string;      // 문자 ON/OFF 설정
    emailOnOff: string;    // 이메일 ON/OFF 설정
    phoneOnOff: string;    // 전화 ON/OFF 설정
}

export type TermsListItem = {
    termName: string;   // 약관명
    termId: string;     // 약관코드
    termYn: string;     // 약관동의 여부
    dpOrder: string;    // 노출순서
    termsType: '125001' | '125002' | '125003' | '125004' | '125005' | '125006'; // 약관타입
};

export type RecvInfo = {
    smsYn: string;      // SMS 수신여부 (Y: 수신, N: 거부)
    pushYn: string;
    aprPushYn: string;    // 승인알림 수신여부 (Y: 수신, N: 여부)
};

export type TermsList = TermsListItem[];
export const initialUserInfo: UserInfo = {
    address1: '',
    address2: '',
    email: '',
    zipCode: ''
}
export type TermsPersonalInfo = {
    termName: string;
    termId: string;
    termYn: string;
    dpOrder: string;
    termsType: string;
    marketingTermYn: string;
}

export const initialTermsPersonalInfo: TermsPersonalInfo = {
    termName: '',
    termId: '',
    termYn: '',
    dpOrder: '',
    termsType: '',
    marketingTermYn: '',
}
