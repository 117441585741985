// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력 (UMembershipReceivedBenefitHistory)
// ****************************************************
import React from 'react';
import {Button, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import box1Img from '../../images/icon/4_my/point.svg';
import box2Img from '../../images/icon/4_my/moneyme_money.svg';
import {MissionReward, MoneyBenefit} from '../../pages/My/types';
import {formatCurrency} from '../../utils/currencyUtils';
import {useNavigate} from 'react-router-dom';
import {appTech, moneymeEnter} from "../../api/externalApi";
import useDebounce from "../../hooks/useDebounce";

interface MissionRewordProps {
    missionReward: MissionReward;
    moneyBenefit: MoneyBenefit;
}

const
    MissionReword: React.FC<MissionRewordProps> = ({missionReward, moneyBenefit}) => {
        const navigate = useNavigate();

        const handleRewordClick = () => {
            appTech();
        };

        const handleMoneyMeClick = useDebounce(() => {
            moneymeEnter('menuCode=H1&scrCode=H4');
        });

        return (
            <>
                <div style={{paddingBottom: '0'}}>
                    {/* ----- 이름 사라짐 ----- */}
                    {/*<TextSet*/}
                    {/*    subComponent='Heading_4'*/}
                    {/*    text={{*/}
                    {/*        title: (*/}
                    {/*            <>*/}
                    {/*                <span style={{color: '#181A1B'}}>나의 미션 리워드 & 머니</span>*/}
                    {/*            </>*/}
                    {/*        ),*/}
                    {/*    }}*/}
                    {/*    blockSpacing={{top: '24px', bottom: '12px'}}*/}
                    {/*    isIndent={true}*/}
                    {/*/>*/}

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                            padding: '10px 20px 24px',
                        }}
                    >
                        {/* -------------- mission 리워드 블록 ------------------*/}
                        <div
                            style={{
                                backgroundColor: '#BEE4F1',
                                borderRadius: '12px',
                                width: 'calc(50% - 4px)',
                                boxSizing: 'border-box',
                                height: '168px',
                                position: 'relative',
                            }}
                            onClick={handleRewordClick}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'미션 리워드|머니Me 현황 - 링크 클릭'}
                            data-gtm-event-label={`컨텐츠 : 미션 이동하기`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'미션 리워드|머니Me 현황'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={`미션 이동하기`}
                        >
                            <div style={{padding: '16px'}}>
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        eyebrow: '내 미션 리워드',
                                        title: (
                                            <>
                                                <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                                    {formatCurrency(missionReward.rewardPoint)}리워드
                                                </Text>
                                            </>
                                        ),
                                    }}
                                    weight='bolder'
                                />
                                <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                                    <Image customClass='customClass' ratio='1:1' src={box1Img} width='64'/>
                                </div>
                            </div>
                        </div>

                        {/* -------------- moneyMe 포인트 블록 ------------------*/}
                        <div
                            style={{
                                backgroundColor: '#FBDCD3',
                                borderRadius: '12px',
                                width: 'calc(50% - 4px)',
                                boxSizing: 'border-box',
                                height: '168px',
                                position: 'relative',
                            }}
                            onClick={handleMoneyMeClick}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'미션 리워드|머니Me 현황 - 링크 클릭'}
                            data-gtm-event-label={`컨텐츠 : 머니Me 이동하기`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'미션 리워드|머니Me 현황'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={`머니Me 이동하기`}>
                            <div style={{padding: '16px'}}>
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        eyebrow: '머니Me',
                                        title: (
                                            <>
                                                <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                                    내 자산 보기
                                                </Text>
                                            </>
                                        ),
                                    }}
                                    weight='bolder'
                                />
                                <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                                    <Image customClass='customClass' ratio='1:1' src={box2Img} width='64'/>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </>
        );
    };

export default MissionReword;
