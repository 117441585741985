import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, CTA, IconButton, Image, TextSet} from '@lguuxe/2024_designsystem_uni';
import {SwipeableHandlers, SwipeableProps, useSwipeable} from 'react-swipeable';
import tutorialImg from "../../images/img/tutorial/tutorial_1.svg";
import tutorial2Img from "../../images/img/tutorial/tutorial_2.svg";
import tutorial3Img from "../../images/img/tutorial/tutorial_3.svg";
import tutorial4Img from "../../images/img/tutorial/tutorial_4.svg";
import {brazeEventApi} from "../../api/brazeBridge";
import {gtmPageLoad} from "../../api/gtm";
import {TutorialInfo} from "./types"
import {decodeHtmlEntities} from "../../utils/textUtils";

interface TextSet {
    title: string;
    // description: JSX.Element;
    description: string;
}

interface TutorialProps {
    onClose: () => void;  // Tutorial을 닫기 위한 콜백 함수
    ttrInfo: TutorialInfo[]; 
}


const Tutorial: React.FC<TutorialProps> = ({onClose, ttrInfo}) => {
    const [textSets, setTextSets] = useState<TextSet[]>([]);
    const [imgSets, setImgSets] = useState<string[]>([]);

    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const newTextSets = ttrInfo.map((ttr) => ({
            title: ttr.ttrTitle,
            // description: (<span style={{paddingTop: "8px"}}>{ttr.ttrContent}</span>),
            description: ttr.ttrContent || ''
        }));
        setTextSets(newTextSets);

        const newImgSets = ttrInfo.map((ttr) => ttr.imgUrl);
        setImgSets(newImgSets);
    },[ttrInfo])

    useEffect(() => {
        const func = async () => {
            await gtmPageLoad({url: '/tutorial', contentGroup: '기타|튜토리얼', moduleName: '', pageTitle: '튜토리얼'});
        }
        func()
    }, [currentIndex]);

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex >= textSets.length - 1) {
                onClose()
                brazeEventApi.tutorial_close();
                return prevIndex; // 현재 인덱스가 마지막 인덱스인 경우 더 이상 증가하지 않음
            }
            return prevIndex + 1;
        });
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
            if (prevIndex <= 0) {
                return prevIndex; // 현재 인덱스가 첫 번째 인덱스인 경우 더 이상 감소하지 않음
            }
            return prevIndex - 1;
        });
    };

    const handleSkipClick = () => {
        brazeEventApi.tutorial_close();
        onClose();  // 튜토리얼 종료
    };

    interface CustomSwipeableProps extends SwipeableProps {
        preventDefaultTouchmoveEvent?: boolean;
    }

    const handlers: SwipeableHandlers = useSwipeable({
        onSwipedLeft: handleNextClick,
        onSwipedRight: handlePrevClick,
        preventDefaultTouchmoveEvent: true, // 추가
        trackMouse: true
    } as CustomSwipeableProps);

    return (
        <>
        {textSets.length > 0 && 
            <div {...handlers} style={{
                // zIndex: 1002, position: 'relative'
                zIndex: 9999,  // 화면에서 가장 앞에 있도록 z-index 값 충분히 높게 설정
                position: 'fixed',  // 고정 위치로 설정
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                overflowY: 'auto',  // 세로 스크롤을 활성화
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box
                    type="2_trbl"
                    style={{
                        marginTop: "16px" // 규격서에는 56px 이다.
                    }}
                >
                    <TextSet
                        subComponent="Heading_2"
                        text= {{
                            title: decodeHtmlEntities(textSets[currentIndex].title),
                            description: decodeHtmlEntities(textSets[currentIndex].description)
                        }}
                        weight="bolder"
                    />
                </Box>
                <Box type="1_trl">
                    <Image
                        ratio={"32:40"}
                        width={"100%"}
                        customClass="customClass"
                        src={imgSets[currentIndex]}
                    />
                </Box>
                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        padding: "4px 0 8px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px"
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            padding: "6px 0px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "6px"
                        }}
                    >
                        {textSets.map((_, index) => (
                            <span
                                key={index}
                                style={{
                                    display: "flex",
                                    backgroundColor: "#66707A",
                                    width: index === currentIndex ? "18px" : "6px",
                                    height: "6px",
                                    borderRadius: index === currentIndex ? "24px" : "50%",
                                    opacity: index === currentIndex ? "1" : "0.4",
                                    cursor: "pointer"
                                }}
                            />
                        ))}
                    </Box>
                </Box>
                <Box
                    style={{
                        // position: "fixed",
                        bottom: "0",
                        width: "100%",
                        padding: currentIndex === (textSets.length - 1) ? "60px 0 0 0":"0"
                    }}
                >
                    <CTA layout="column">
                        <IconButton
                            color="neutral"
                            icon="visibility_filled"
                        />
                        <Button
                            color="primary"
                            onClick={handleNextClick}
                        >
                            다음
                        </Button>
                        {currentIndex !== (textSets.length - 1) && (
                            <Button color="neutral" fill="ghost" onClick={handleSkipClick}>
                                건너뛰기
                            </Button>)
                        }
                    </CTA>
                </Box>
            </div>
        }
        </>
    );
}

export default Tutorial;
