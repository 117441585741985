// ****************************************************
// [배포] 5.전체 > 테이터 선물 > 테이터 선물하기 > 선물하기 (UMembershipMyDatagift)
// ****************************************************
// 선물 메시지
import React, { Component } from "react";
import { Box, TextSet, TextArea, Text } from "@lguuxe/2024_designsystem_uni";

interface SectionHeadingTextareaT {
    msg: string;
    setMsg: React.Dispatch<React.SetStateAction<string>>;
}

const SectionHeadingTextarea = ({ msg, setMsg }: SectionHeadingTextareaT) => {
    return (
        <Box type="2_trbl" style={{ paddingBottom: "24px" }}>
            <Text typo="font_heading_4B" color="color_text_neutral_6">
                선물 메시지
            </Text>
            <Box style={{ marginTop: "12px", padding: 0}}>
                <TextArea
                    label=""
                    maxLength={200}
                    onChange={(e) => {
                        setMsg(e.value);
                    }}
                    placeholder="데이터 선물과 함께 보내고 싶은 메시지를 작성해주세요. "
                    rows={5}
                    status="default"
                    // supportText={[
                    //   {
                    //     text: "state, icon 미지정"
                    //   },
                    //   {
                    //     icon: "",
                    //     status: "default",
                    //     text: "state: default, icon 미지정"
                    //   },
                    //   {
                    //     icon: "",
                    //     status: "error",
                    //     text: "state: error, icon 미지정"
                    //   },
                    //   {
                    //     icon: "",
                    //     status: "success",
                    //     text: "state: success, icon 미지정"
                    //   }
                    // ]}
                />
            </Box>
        </Box>
    );
};

export default SectionHeadingTextarea;
