import React, { useEffect, useState } from "react";
import { Box, Chip, Tab } from "@lguuxe/2024_designsystem_uni";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
// import MembershipInfoTab from "../../components/BenefitGuide/Tab/MembershipInfoTab";
// import MembershipUsageTab from "../../components/BenefitGuide/Tab/MembershipUsageTab";
// import MobilePaymentTab from "../../components/BenefitGuide/Tab/MobilePatmentTab";
// import MoneyMeTab from "../../components/BenefitGuide/Tab/MoneyMeTab";
// import YouthTab from "../../components/BenefitGuide/Tab/YouthTab";
// import LongTermCustomerTab from "../../components/BenefitGuide/Tab/LongTermCustomerTab";
// import MembershipBenefitTab from "../../components/BenefitGuide/Tab/MemeberShipBenefitTab";
// import MissionTab from "../../components/BenefitGuide/Tab/MissionTab";
import { useParams } from "react-router-dom";
import { gtmPageLoad } from "../../api/gtm";
import { handlePromiseResult } from "../../api/api";
import benefitApi from '../../api/gatewayApi/benefit';
import { GuideInfoList, GuideInfo } from './types';
import TabContent from "./TabContent";
import { useNavigate } from "react-router-dom";
import { sendEventReturn } from "../../api/nativeBridge";
import useLoading from "../../hooks/useLoading";

const initialGuideInfoList: GuideInfoList = {
    beneAppList: [],
};

declare global {
    interface Window {
        guideFunctions?: {
            goVIPkok: () => void;
            goUPTP: () => void;
            goPartners: () => void;
            goUPlus: () => void;
        };
    }
}

const BenefitGuide: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // id 가져오기
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [mainTab, setMainTab] = useState<string>("U");
    const [subTab, setSubTab] = useState<string>("11");
    const [guideListU, setGuideListU] = useState<GuideInfoList>(initialGuideInfoList);
    const [guideListH, setGuideListH] = useState<GuideInfoList>(initialGuideInfoList);
    // innerHtml 테스트용 URL https://phet-dev.colorado.edu/html/build-an-atom/0.0.0-3/simple-text-only-test-page.html
    const [contentUrl, setContentUrl] = useState<string>("");
    const navigate = useNavigate()

    // 스크립트 정의와 함수 추가
    useEffect(() => {
        // window 객체에 guideFunctions 객체 생성
        window.guideFunctions = {
            goVIPkok: () => {
                navigate('/vip-kok')
            },
            goUPTP: () => {
                navigate('/uptp')
            },
            goPartners: () => {
                navigate('/partners')
            },
            goUPlus: () => {
                sendEventReturn('EXTERNAL_CALL', {
                    type: '1',
                    url: 'https://app.lguplus.com/apcm/html-push?url=/benefit-uplus/loyal-member-perks/grade'
                });
            },

        };

        // cleanup function
        return () => {
            if (window.guideFunctions) {
                delete window.guideFunctions;
            }
        };
    }, []);

    useEffect(() => {
        if (id) {
            const [main, sub] = id.split(""); // id 분할
            setMainTab(main === '1' ? 'U' : 'H');
            setSubTab(id);
        }

        const func = async () => {
            try {
                const [Uguide, Hguide] = await Promise.allSettled(
                    [
                        // benefitApi.getGuide({reqType1: '10',reqType2: '12'},'v1'),
                        benefitApi.getGuide({ reqType: '10' }, 'v1'),
                        benefitApi.getGuide({ reqType: '20' }, 'v1'),
                    ]
                );
                setGuideListU(handlePromiseResult(Uguide, initialGuideInfoList));
                setGuideListH(handlePromiseResult(Hguide, initialGuideInfoList));

            } catch (error) {
                console.log('Failed to fetch benefit guide:', error);
            } finally {
                setIsLoading(false);
                window.scrollTo(0, 0.1);
            }

            await gtmPageLoad({
                url: '/benefit-guide',
                contentGroup: `혜택 이용 가이드|U+멤버십 소개`,
                moduleName: '',
                pageTitle: '혜택 이용 가이드'
            });
        }
        func();
    }, []);

    useEffect(() => {
        if (guideListU.beneAppList.length < 1 || guideListH.beneAppList.length < 1) return;

        if (mainTab === 'U') {
            const cUrl = guideListU.beneAppList.find(be => subTab === be.beneType2);
            if (cUrl) setContentUrl(cUrl.publFileUrl);
        } else {
            const cUrl = guideListH.beneAppList.find(be => subTab === be.beneType2);
            if (cUrl) setContentUrl(cUrl.publFileUrl);
        }
    }, [subTab, guideListU, guideListH])

    const handleMainTabChange = async (id: string) => {
        setMainTab(id);
        setSubTab(id === 'U' ? '11' : '21');
        await gtmPageLoad({
            url: '/benefit-guide',
            contentGroup: `혜택 이용 가이드|${id === 'U' ? 'U+멤버십 소개' : '이용 방법'}`,
            moduleName: '',
            pageTitle: '혜택 이용 가이드'
        });
    };

    const handleSubTabChange = (id: string) => {
        setSubTab(id);
    };

    return (
        <>
            {useLoading(isLoading)}
            <Box type="1_b" style={{ width: "100%" }}>
                <CommonLeftIconHeader title={"혜택 가이드"} />

                <Box type="1_b">
                    <Tab
                        items={[
                            {
                                id: "U", label: 'U+멤버십 소개',
                                dataAttributes: {
                                    'data-gtm-event-name': 'all_click',
                                    'data-gtm-event-category': '클릭',
                                    'data-gtm-event-action': 'LNB - 링크 클릭',
                                    'data-gtm-event-label': '컨텐츠 : type U',
                                    'data-gtm-click-url': '',
                                    'data-gtm-click-location': 'LNB',
                                    'data-gtm-click-direction': '내부',
                                    'data-gtm-click-text': 'type U',
                                }
                            },
                            {
                                id: "H", label: "이용방법",
                                dataAttributes: {
                                    'data-gtm-event-name': 'all_click',
                                    'data-gtm-event-category': '클릭',
                                    'data-gtm-event-action': 'LNB - 링크 클릭',
                                    'data-gtm-event-label': '컨텐츠 : type H',
                                    'data-gtm-click-url': '',
                                    'data-gtm-click-location': 'LNB',
                                    'data-gtm-click-direction': '내부',
                                    'data-gtm-click-text': 'type H',
                                }
                            },
                        ]}
                        layout="fullWidth"
                        selectedItemId={mainTab}
                        onItemSelected={handleMainTabChange}
                        style={{ padding: "0 20px" }}
                    />

                    {mainTab === "U" && (
                        <Box type="4_tb" style={{
                            paddingTop: "16px", paddingBottom: "12px", display: "flex", gap: "6px", overflowX: "auto",  // 가로 스크롤 활성화
                            whiteSpace: "nowrap"
                        }}>
                            <div style={{ padding: "0 20px", display: "flex", gap: '0.5rem' }}>
                                {guideListU.beneAppList.length > 0 && guideListU.beneAppList.map((guide, index) => (
                                    <Chip.Filter
                                        isSelected={subTab === guide?.beneType2}
                                        color="neutral"
                                        fill={subTab === guide?.beneType2 ? 'solid' : 'outline'}
                                        label={guide.beneTitle}
                                        onClick={() => handleSubTabChange(guide?.beneType2)}
                                        size="medium"
                                        data-gtm-event-name="all_click"
                                        data-gtm-event-category="클릭"
                                        data-gtm-event-action="카테고리 - 링크 클릭"
                                        data-gtm-event-label={"컨텐츠 : " + guide.beneTitle}
                                        data-gtm-click-url=""
                                        data-gtm-click-location="카테고리"
                                        data-gtm-click-direction="내부"
                                        data-gtm-click-text={guide.beneTitle}
                                    />
                                ))}
                            </div>
                        </Box>
                    )}
                    {mainTab === "H" && (
                        <Box type="4_tb" style={{
                            paddingTop: "16px", paddingBottom: "12px", display: "flex", gap: "6px", overflowX: "auto",  // 가로 스크롤 활성화
                            whiteSpace: "nowrap"
                        }}>
                            <div style={{ padding: "0 20px", display: "flex", gap: '0.5rem' }}>
                                {guideListH.beneAppList.length > 0 && guideListH.beneAppList.map((guide, index) => (
                                    <Chip.Filter
                                        isSelected={subTab === guide?.beneType2}
                                        color="neutral"
                                        fill={subTab === guide?.beneType2 ? 'solid' : 'outline'}
                                        label={guide.beneTitle}
                                        onClick={() => handleSubTabChange(guide?.beneType2)}
                                        size="medium"
                                        data-gtm-event-name="all_click"
                                        data-gtm-event-category="클릭"
                                        data-gtm-event-action="카테고리 - 링크 클릭"
                                        data-gtm-event-label={"컨텐츠 : " + guide.beneTitle}
                                        data-gtm-click-url=""
                                        data-gtm-click-location="카테고리"
                                        data-gtm-click-direction="내부"
                                        data-gtm-click-text={guide.beneTitle}
                                    />
                                ))}
                            </div>
                        </Box>
                    )}

                    <TabContent url={contentUrl} />
                </Box>
            </Box>
        </>
    );
};

export default BenefitGuide;
